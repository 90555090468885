<script setup>
import { ref, defineExpose, defineEmits, watch, computed } from 'vue';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import { getClientSesmt } from '../../../../services/http-sesmt';
import { GrupoRiscosLabelEnum } from '../../../../enums/GrupoRiscos';
import { StatusGroGrupoRiscosElaboracaoEnum, styleMapStatusGrupoRiscos } from '../../../../enums/StatusGroGrupoRiscosElaboracao';
import { useAlert } from '../../../../composables/useAlert';
import { useRouter } from 'vue-router';

import EtapasInventarioRiscoEnum from '../../../../enums/EtapasInventarioRiscoEnum';
import DropdownRiscos from '../form-caracterizacao/DropdownRiscos.vue';
import StatusGrid from '../StatusGrid.vue';

const emit = defineEmits(['onSuccessExposicao']);

const { currentRoute } = useRouter();
const { alertSucess, alertErrorData } = useAlert();

const rules = {
    fatorRisco: {
        required: helpers.withMessage('O Fator de risco é obrigatório.', required),
        lazy: true
    },
    dataInicial: {
        required: helpers.withMessage('A Data inicial é obrigatória.', required),
        lazy: true
    },
    dataFinal: {
        lazy: true,
        required: helpers.withMessage(
            'A Data final é obrigatória.',
            requiredIf(() => !form.value?.exposicaoAtiva)
        )
    },
    exposicaoAtiva: { default: false }
};

const tituloDialog = ref('Nova exposição');
const isOpen = ref(false);
const hierarquia = ref();
const isLoading = ref(false);
const form = ref({});
const statusGrupoRiscosOpcoes = ref([]);

const desabilitarDataFinal = computed(() => !!form.value.exposicaoAtiva);

watch(
    () => form.value.exposicaoAtiva,
    () => {
        if (form.value.exposicaoAtiva) {
            form.value.dataFinal = null;
        }
    }
);

const labelEnumRisco = GrupoRiscosLabelEnum;

const v$ = useVuelidate(rules, form);

const salvarExposicao = async () => {
    try {
        const isValid = await v$.value.$validate();
        if (!isValid) return;

        isLoading.value = true;
        const visitaTecnicaId = currentRoute.value.params.visitaTecnicaId;
        const customerId = currentRoute.value?.params?.id;

        const response = await getClientSesmt().post('aplicacao-pgr-elaboracao/caracterizacao/nova-exposicao', {
            inventarioRiscoVisitaTecnicaId: visitaTecnicaId,
            inventarioHierarquiaId: hierarquia.value?.inventarioHierarquiaId,
            clienteId: customerId,
            hierarquiaId: hierarquia.value?.hierarquia.id,
            riscoId: form.value.fatorRisco.id,
            tipoAvaliacaoRisco: form.value.fatorRisco.tipoAvaliacao,
            exposicaoAtiva: form.value.exposicaoAtiva,
            dataInicio: form.value.dataInicial,
            dataFim: form.value.dataFinal,
            etapa: EtapasInventarioRiscoEnum.PGR
        });

        hierarquia.value.inventarioHierarquiaId = response.data.inventarioHierarquiaId;

        const eventHierarquia = {
            data: hierarquia.value,
            indexHierarquia: indexHierarquia.value
        };

        emit('onSuccessExposicao', eventHierarquia);
        alertSucess('Exposição salva com sucesso.');
        closeDialog();
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi possível salvar registro, por favor tente novamente.');
    }
};

const getStatusGrupoRiscosOpcoes = () => {
    statusGrupoRiscosOpcoes.value = Object.keys(StatusGroGrupoRiscosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroGrupoRiscosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusGrupoRiscos[StatusGroGrupoRiscosElaboracaoEnum[type]]
        };
    });
};

const getRiscosComTodasAsConfigs = (status) => statusGrupoRiscosOpcoes.value.find((p) => p.label === status);

const indexHierarquia = ref();
const openDialog = (hierarquiaRecebida, indexExpandHierarquia) => {
    indexHierarquia.value = indexExpandHierarquia;
    getStatusGrupoRiscosOpcoes();
    hierarquia.value = hierarquiaRecebida;
    isOpen.value = true;
};

const closeDialog = () => {
    form.value = {};
    v$.value.$reset();
    isOpen.value = false;
};

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '450px' }"
        :header="tituloDialog"
        @hide="closeDialog"
        :draggable="false"
        modal
        :closable="!isLoading"
    >
        <div class="grid">
            <div class="field col-8 mb-0">
                <label for="dropdownRiscos">Perigo / Fator de risco</label>
                <DropdownRiscos
                    id="dropdownRiscos"
                    v-model="v$.fatorRisco.$model"
                    class="w-full"
                    :class="{ 'p-invalid': v$.fatorRisco.$errors.length && v$.fatorRisco.lazy.$dirty }"
                />
                <small v-if="v$.fatorRisco.$errors.length && v$.fatorRisco.lazy.$dirty" class="p-error">
                    {{ v$.fatorRisco.$errors[0].$message }}
                </small>
            </div>
            <div class="field col-4 mb-0">
                <label for="dropdownRiscos">Grupo</label>
                <StatusGrid
                    class="w-full"
                    v-if="form.fatorRisco?.grupoRisco"
                    :status="getRiscosComTodasAsConfigs(labelEnumRisco.get(form.fatorRisco?.grupoRisco))"
                />
                <div v-if="!form.fatorRisco?.grupoRisco" class="w-full"><strong>Não informado</strong></div>
            </div>
            <div class="field col-6">
                <label for="dataInicio">Data inicial</label>
                <Calendar
                    showIcon
                    id="dataInicio"
                    v-model="v$.dataInicial.$model"
                    :class="{ 'p-invalid': v$.dataInicial.$errors.length && v$.dataInicial.lazy.$dirty }"
                    :maxDate="form.dataFim"
                    :disabledDays="[0, 6]"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                />
                <small v-if="v$.dataInicial.$errors.length && v$.dataInicial.lazy.$dirty" class="p-error">
                    {{ v$.dataInicial.$errors[0].$message }}
                </small>
            </div>
            <div class="field col-6">
                <label for="dataFim">Data final</label>
                <Calendar
                    showIcon
                    id="dataFim"
                    :minDate="form.dataInicial"
                    v-model="v$.dataFinal.$model"
                    :disabledDays="[0, 6]"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                    :disabled="desabilitarDataFinal"
                    :class="{ 'p-invalid': v$.dataFinal.$errors.length && v$.dataFinal.lazy.$dirty }"
                />
                <small v-if="v$.dataFinal.$errors.length && v$.dataFinal.lazy.$dirty" class="p-error">
                    {{ v$.dataFinal.$errors[0].$message }}
                </small>
            </div>
        </div>
        <div class="flex flex-row align-items-start justify-content-end">
            <Checkbox class="field-checkbox" v-model="v$.exposicaoAtiva.$model" inputId="exposicaoAtiva" binary name="exposicaoAtiva" :value="true" />
            <label for="exposicaoAtiva" class="ml-2"> Exposição ativa </label>
        </div>
        <div class="flex justify-content-end align-items-center mt-3">
            <Button class="button-link" @click="closeDialog" text label="Cancelar" />
            <Button class="ml-5 button-salvar" type="button" label="Adicionar" @click="salvarExposicao" />
        </div>
    </Dialog>
</template>
<style scoped lang="scss"></style>
