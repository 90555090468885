import moment from 'moment';

export const useMoment = () => {
    function parseDateTz(dateString) {
        return moment(dateString, 'DD/MM/YYYY').toISOString(); // Ou outro formato que você deseja
    }

    function formatDateTz(date) {
        return moment.tz(date, 'UTC').format('DD/MM/YYYY');
    }

    function formatDateBr(date) {
        const brDate = moment(date).tz('America/Sao_Paulo');
        const formattedDate = new Date(brDate.format());
        return formattedDate;
    }

    return {
        formatDateTz,
        formatDateBr,
        parseDateTz
    };
};
