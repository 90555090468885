<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useAlert } from '../../composables/useAlert';
import { getClientSesmt } from '../../services/http-sesmt';
import { StatusGroGrupoRiscosElaboracaoEnum, styleMapStatusGrupoRiscos } from '../../enums/StatusGroGrupoRiscosElaboracao';
import { useMoment } from '../../composables/useMoment';

import StatusGrid from './components/StatusGrid';
import SesmtService from '../../services/SesmtService';
import StatusGroHierarquiasElaboracaoEnum from '../../enums/StatusGroHierarquiasElaboracao';
import HeaderPanel from './components/HeaderPanel';
import FiltroInventarioRisco from './components/inventario-risco/FiltroInventarioRisco.vue';
import DialogExposicao from './components/inventario-risco/DialogExposicao.vue';
import DialogExcluirExposicao from './components/inventario-risco/DialogExcluirExposicao.vue';
import TipoAvaliacaoRisco from '../../enums/TipoAvaliacaoRisco';
import { GrupoRiscosLabelEnum } from '../../enums/GrupoRiscos';
import StatusGroQuestionariosElaboracaoEnum from '../../enums/StatusGroQuestionariosElaboracao';

const { currentRoute, push, replace } = useRouter();
const { alertError } = useAlert();
const { formatDateTz } = useMoment();

const customer = ref({});
const labelEnumRisco = GrupoRiscosLabelEnum;
const filtrosDoComponente = ref({});
const store = useStore();
const loading = ref(false);
const loadingRespostaPerigo = ref();
const riscosColunas = [
    {
        field: 'risco',
        header: 'Risco'
    },
    {
        field: 'grupo',
        header: 'Grupo'
    },
    {
        field: 'caracterizacao',
        header: 'Caracterização'
    },
    {
        field: 'matrizConfigLinha',
        header: 'Nível de risco'
    },
    {
        field: 'medicao',
        header: 'Medição'
    },
    {
        field: 'dataInicio',
        header: 'Inicio'
    },
    {
        field: 'dataFim',
        header: 'Fim'
    },
    {
        field: 'acoes',
        style: 'width: 55px'
    }
];
const queryParams = ref({});
const records = ref();
const dialogExposicao = ref();
const dialogExcluirExposicao = ref();
const page = ref(1);
const recordsPerPage = ref(10);
const totalRecords = ref(0);
const totalPages = ref(0);
const selectedSetor = ref([]);
const expandedRows = ref();
const statusHierarquiasElaboracaoOpcoes = ref([]);
const statusGrupoRiscosElaboracaoOpcoes = ref([]);
const statusCaracterizacaoRiscoOpcoes = ref([]);

const openDialogExposicao = (data, indexHierarquia) => dialogExposicao.value.openDialog(data, indexHierarquia);
const openDialogExcluirExposicao = (data, indexHierarquia) => dialogExcluirExposicao.value.openDialog(data, indexHierarquia);

const calcularMedicao = (respostaPerigo) => {
    const medicao = respostaPerigo.medicao;
    const nivelAcao = respostaPerigo.risco.nivelAcao;
    const limiteTolerancia = respostaPerigo.risco.limiteTolerancia;
    const valorAlertaMin = respostaPerigo.risco.valorAlertaMin;
    const valorAlertaMax = respostaPerigo.risco.valorAlertaMax;

    if (medicao < nivelAcao) {
        return {
            backgroundColor: '#fff',
            color: ' #000000',
            border: '1px solid #0E4005',
            status: 'Abaixo do nível de ação'
        };
    }

    if (medicao >= nivelAcao && medicao < limiteTolerancia) {
        return {
            backgroundColor: '#fff',
            color: ' #000000',
            border: '1px solid #F7FFC6',
            status: 'Entre nível de ação e limite de tolerância'
        };
    }

    if (medicao >= limiteTolerancia) {
        return {
            backgroundColor: '#ffffff',
            color: ' #000000',
            border: '1px solid #700D06',
            status: 'Acima do limite de tolerância'
        };
    }

    if (medicao < valorAlertaMin || medicao > valorAlertaMax) {
        return {
            backgroundColor: '#ffffff',
            color: ' #000000',
            border: '1px solid #653E11'
        };
    }
};

const styleMapStatus = {
    [StatusGroHierarquiasElaboracaoEnum.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroHierarquiasElaboracaoEnum.SEM_APLICACAO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.VALIDACAO_EM_ANDAMENTO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.PENDENTE_AVALIADOR]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const styleMapStatusCaracterizacaoRisco = {
    [StatusGroQuestionariosElaboracaoEnum.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroQuestionariosElaboracaoEnum.AGUARDANDO_RETORNO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },

    [StatusGroQuestionariosElaboracaoEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroQuestionariosElaboracaoEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const onPage = async (event) => {
    page.value = event.page + 1;
    recordsPerPage.value = event.rows;
    await load();
};

const aplicarFiltros = async (removerFiltros = false) => {
    if (removerFiltros) {
        filtrosDoComponente.value = {};
        atualizarQueryParamsStore({});
    }

    await load();
};

const atualizarQueryParams = async () => {
    const { setor, cargo, statusGro } = filtrosDoComponente.value;

    atualizarQueryParamsStore({
        setorId: setor?.id ? JSON.stringify(setor.id) : undefined,
        cargoId: cargo?.id ? JSON.stringify(cargo.id) : undefined,
        statusGro: statusGro ? JSON.stringify(statusGro) : undefined
    });

    await replace({
        query: {
            setorId: setor?.id ? JSON.stringify(setor.id) : undefined,
            cargoId: cargo?.id ? JSON.stringify(cargo.id) : undefined,
            statusGro: statusGro ? JSON.stringify(statusGro) : undefined
        }
    });
};

const atualizarQueryParamsStore = (params) => store.dispatch('setQueryParamsTelaInventarioRiscoGro', params);

const getStatusHierarquiasElaboracaoOpcoes = () => {
    statusHierarquiasElaboracaoOpcoes.value = Object.keys(StatusGroHierarquiasElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroHierarquiasElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroHierarquiasElaboracaoEnum[type]]
        };
    });
};

const getStatusGrupoRiscosElaboracaoOpcoes = () => {
    statusGrupoRiscosElaboracaoOpcoes.value = Object.keys(StatusGroGrupoRiscosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroGrupoRiscosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusGrupoRiscos[StatusGroGrupoRiscosElaboracaoEnum[type]]
        };
    });
};

const getStatusCaracterizacaoRiscoElaboracaoOpcoes = () => {
    statusCaracterizacaoRiscoOpcoes.value = Object.keys(StatusGroQuestionariosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroQuestionariosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusCaracterizacaoRisco[StatusGroQuestionariosElaboracaoEnum[type]]
        };
    });
};

const getStatusComTodasAsConfigs = (status) => statusHierarquiasElaboracaoOpcoes.value.find((p) => p.value === status);

const getStatusGrupoRiscosComTodasAsConfigs = (status) => statusGrupoRiscosElaboracaoOpcoes.value.find((p) => p.label === status);

const getStatusCaracterizacaoRiscoComTodasAsConfigs = (status) => statusCaracterizacaoRiscoOpcoes.value.find((p) => p.label === status);

const getStatusNivelRiscoComTodasAsConfigs = (matrizConfigLinhaEixoR) => {
    return {
        label: matrizConfigLinhaEixoR.titulo,
        value: matrizConfigLinhaEixoR.titulo,
        style: {
            backgroundColor: matrizConfigLinhaEixoR.grupo.cor,
            color: getTextColor(matrizConfigLinhaEixoR.grupo.cor)
        }
    };
};

function getTextColor(backgroundColor) {
    const textColorMap = {
        '#b6e9b6': '#0E4005',
        '#00cc44': '#0E4005',
        '#FFFF00': '#644D12'
    };

    return textColorMap[backgroundColor] ?? '#000';
}

const menu = ref({});
const toggleMenu = async (event, indexExpand, index) => {
    menu.value?.[`${indexExpand}-${index}`].toggle(event);
};

const redirectCaracterizacao = (id, hierarquias) => {
    const setor = hierarquias?.hierarquia.customerDepartment.name;
    const cargo = hierarquias?.hierarquia.customerPosition.name;
    const basePath = currentRoute.value.path.endsWith('/') ? currentRoute.value.path : currentRoute.value.path + '/';
    if (!id) {
        return push({ path: `${basePath}caracterizacao` });
    }
    return push({ path: `${basePath}caracterizacao/${id}/setor/${setor}/cargo/${cargo}` });
};

const getActionItems = (record, hierarquias, indexHierarquia) => {
    return [
        {
            label: 'Acessar caracterização',
            icon: 'pi pi-file',
            disabled: () => {},
            command: () => {
                redirectCaracterizacao(record.id, hierarquias);
            }
        },
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            disabled: () => true,
            command: () => openDialogExposicao(record)
        },
        {
            label: 'Excluir',
            icon: 'pi pi-trash',
            disabled: () => {},
            command: () => openDialogExcluirExposicao({ id: record.id, hierarquiaRecebida: hierarquias }, indexHierarquia)
        }
    ];
};

const getCustomer = async (customerId) => {
    const { data } = await new SesmtService('/customers').findById(customerId);
    customer.value = data;
};

const dataTableKey = ref(0);
const load = async () => {
    try {
        loading.value = true;
        const visitaTecnicaId = currentRoute.value.params.visitaTecnicaId;
        const unidadeId = currentRoute.value.params.idUnidade;
        const { data } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/find-hierarquias/unidade/${unidadeId}/visita-tecnica/${visitaTecnicaId}`,
            {
                params: {
                    limit: recordsPerPage.value,
                    page: page.value,
                    filtrosExtras: {
                        setorId: filtrosDoComponente.value?.setor?.id,
                        cargoId: filtrosDoComponente.value?.cargo?.id,
                        statusGro: filtrosDoComponente.value?.statusGro
                    }
                }
            }
        );
        records.value = data.items;
        totalRecords.value = data.meta?.totalItems;
        totalPages.value = data.meta?.totalPages;
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
        dataTableKey.value++;
    }
};

const loadRespostaPerigoPorHierarquia = async (event) => {
    if (!event.data?.inventarioHierarquiaId) return;

    try {
        event.data.respostasPerigos = [];
        loadingRespostaPerigo.value = event.data?.inventarioHierarquiaId;

        const { data } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/find-aplicacao-respostas-perigos/inventario-hierarquia/${event.data?.inventarioHierarquiaId}`
        );

        event.data.respostasPerigos = data;
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        setStatusHierarquia(event.indexHierarquia, event.data.respostasPerigos);
        loadingRespostaPerigo.value = undefined;
    }
};

const setStatusHierarquia = (indexHierarquia, respostasPerigos) => {
    const hasPendente = respostasPerigos.some((exposicao) => exposicao.statusCaracterizacao === StatusGroQuestionariosElaboracaoEnum.PENDENTE);

    if (!records.value?.[indexHierarquia]?.hierarquia?.statusGro) return;

    const getNovoStatus = (valorStatus) => statusHierarquiasElaboracaoOpcoes.value.find((p) => p.label === valorStatus).value;

    if (hasPendente) {
        records.value[indexHierarquia].hierarquia.statusGro = getNovoStatus(StatusGroHierarquiasElaboracaoEnum.PENDENTE);
        return;
    }

    records.value[indexHierarquia].hierarquia.statusGro = getNovoStatus(StatusGroHierarquiasElaboracaoEnum.VALIDADO);
};

const init = async () => {
    const routeQueryParams = currentRoute.value?.query;
    queryParams.value = Object.keys(routeQueryParams).length ? routeQueryParams : store.getters.queryParamsTelaAmbienteGro;
    if (Object.keys(queryParams.value).length) {
        await atualizarQueryParams();
    } else {
        filtrosDoComponente.value = {
            name: queryParams.value?.name,
            setorId: queryParams.value?.setorId,
            cargoId: queryParams.value?.cargoId,
            statusGro: queryParams.value?.statusGro
        };

        await atualizarQueryParams();
    }

    const customerId = currentRoute.value?.params?.id ? Number(currentRoute.value?.params?.id) : 0;
    getStatusHierarquiasElaboracaoOpcoes();
    getStatusGrupoRiscosElaboracaoOpcoes();
    getStatusCaracterizacaoRiscoElaboracaoOpcoes();
    await getCustomer(customerId);
    await load();
};

onBeforeMount(init);
</script>
<template>
    <div class="card">
        <HeaderPanel tooltip="Inventário de risco" :title="'Inventário de risco'" nomeTelaDoManual="customer-inventario-risco-gro">
            <template #extraContent>
                <div class="flex flex-row justify-content-center align-items-center gap-2 identificacao-customer">
                    <i class="pi pi-building text-4xl"></i>
                    <div class="flex flex-column">
                        <span class="font-semibold line-height-3 cursor-pointer nome-cliente" v-tooltip.bottom="customer?.name">{{
                            customer?.name
                        }}</span>
                        <span class="">Cód. SOC - {{ customer?.code }}</span>
                    </div>
                </div>
            </template>
        </HeaderPanel>
        <FiltroInventarioRisco v-model:filtrosExtras="filtrosDoComponente" @loadInventarioRisco="aplicarFiltros" />

        <DataTable
            :key="dataTableKey"
            v-model:expandedRows="expandedRows"
            v-model:selection="selectedSetor"
            :value="records"
            dataKey="hierarquia.id"
            tableStyle="min-width: 50rem"
            size="large"
            :loading="loading"
            @rowExpand="loadRespostaPerigoPorHierarquia"
        >
            <template #empty> Nenhum registro encontrado.</template>
            <template #loading>
                <AppLoadingWrapper />
            </template>
            <Column expander style="width: 5rem" />
            <Column field="hierarquia.id">
                <template #body="slotProps">
                    <div class="column-header flex justify-content-between">
                        <div class="header-description flex flex-column">
                            <strong class="title">{{ slotProps.data?.hierarquia.customerDepartment.name }}</strong>
                            <span class="description">{{ slotProps.data?.hierarquia.customerPosition.name }}</span>
                        </div>

                        <div class="header status">
                            <StatusGrid
                                v-if="slotProps.data?.hierarquia.statusGro"
                                :status="getStatusComTodasAsConfigs(slotProps.data?.hierarquia.statusGro)"
                            />
                        </div>
                    </div>
                </template>
            </Column>
            <template #expansion="{ data: hierarquias, index: indexExpand }">
                <div class="p-3">
                    <DataTable
                        dataKey="id"
                        :loading="hierarquias.inventarioHierarquiaId === loadingRespostaPerigo"
                        :value="hierarquias.respostasPerigos"
                    >
                        <template #empty> {{ !hierarquias.inventarioHierarquiaId ? 'Sem aplicação' : 'Ausência de risco' }}</template>
                        <template #loading>
                            <AppLoadingWrapper />
                        </template>

                        <Column v-for="col of riscosColunas" :field="col.field" :key="col.field" :style="col.style">
                            <template #header>
                                <div class="font-semibold">{{ col.header }}</div>
                            </template>

                            <template #body="{ data, index }">
                                <template v-if="col.field === 'acoes'">
                                    <Button
                                        class="p-button-text p-button-secondary"
                                        icon="pi pi-ellipsis-v"
                                        @click="toggleMenu($event, indexExpand, index)"
                                    />

                                    <Menu
                                        :ref="(el) => (menu[`${indexExpand}-${index}`] = el)"
                                        :model="getActionItems(data, hierarquias, indexExpand)"
                                        :popup="true"
                                    />
                                </template>
                                <template v-else-if="col.field === 'grupo'">
                                    <StatusGrid :status="getStatusGrupoRiscosComTodasAsConfigs(labelEnumRisco.get(data.risco.grupoRisco))" />
                                </template>
                                <template v-else-if="col.field === 'caracterizacao'">
                                    <StatusGrid :status="getStatusCaracterizacaoRiscoComTodasAsConfigs(data.statusCaracterizacao)" />
                                </template>

                                <template v-else-if="col.field === 'matrizConfigLinha'">
                                    <StatusGrid
                                        v-if="data?.matrizConfigLinhaEixoRId"
                                        :status="getStatusNivelRiscoComTodasAsConfigs(data?.matrizConfigLinhaEixoR)"
                                    />
                                    <span v-else>Sem informação</span>
                                </template>

                                <template v-else-if="col.field === 'risco'">
                                    {{ data[col.field].nome }}
                                </template>
                                <template v-else-if="col.field === 'medicao'">
                                    <span v-if="data.tipoAvaliacaoRisco !== TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO">Não se aplica</span>

                                    <Tag
                                        v-if="data[col.field]"
                                        :style="calcularMedicao(data ?? {})"
                                        v-tooltip.bottom="calcularMedicao(data ?? {}).status"
                                        :value="data[col.field]?.toFixed(2)"
                                    />
                                    <span v-else-if="data.tipoAvaliacaoRisco === TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO">Sem informação</span>
                                </template>

                                <template v-else-if="col.field === 'dataInicio'"> {{ formatDateTz(data.dataInicio) }} </template>
                                <template v-else-if="col.field === 'dataFim'">
                                    {{ data?.exposicaoAtiva ? 'Exposição ativa' : formatDateTz(data?.dataFim) }}
                                </template>
                                <template v-else>
                                    <span>{{ data[col.field] }}</span>
                                </template>
                            </template>
                        </Column>
                        <template #footer>
                            <Button
                                rounded
                                label="Nova exposição"
                                icon="pi pi-plus"
                                class="p-button-text"
                                @click="openDialogExposicao(hierarquias, indexExpand)"
                            />
                        </template>
                    </DataTable>
                </div>
            </template>
        </DataTable>
        <Paginator
            v-show="!loading"
            :rows="recordsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[10, 50, 100]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            @page="onPage"
        >
        </Paginator>
        <DialogExcluirExposicao ref="dialogExcluirExposicao" @onSuccessExcluirExposicao="loadRespostaPerigoPorHierarquia" />
        <DialogExposicao @onSuccessExposicao="loadRespostaPerigoPorHierarquia" ref="dialogExposicao" />
    </div>
</template>
